import React, { useContext, useEffect, useState } from 'react';
import Loader from './shared/Loader';
import { ThemeContext } from './ThemeContext';
import { useNavigate } from 'react-router-dom';
import { BASEURL } from './shared/Config';

const DeliveryBoyList = (searchQuery) => {
  const [sortedExecutives, setSortedExecutives] = useState([]);
  const [sortBy, setSortBy] = useState('default');
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [managers, setManagers] = useState([]);
  const [executives, setExecutives] = useState([]);
  const [loading, setLoading] = useState(false);
  const { mode } = useContext(ThemeContext);
  const navigate = useNavigate();
  // Pagination state
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10); // Number of items per page
  const [totalPages, setTotalPages] = useState(1);

  useEffect(() => {
    fetchDelivery(currentPage);
  }, [currentPage]);

  let filteredManagers = sortedExecutives;

  if (searchQuery.searchQuery.length > 2) {
    filteredManagers = sortedExecutives.filter(manager =>
      manager.name.toLowerCase().includes(searchQuery.searchQuery.toLowerCase())
    )

  }
  else filteredManagers = sortedExecutives;

  const fetchDelivery = async (page) => {
    setLoading(true);
    try {
      const response = await fetch(`${BASEURL}delivery-person?page=${page}&limit=${itemsPerPage}&sortBy=createdAt:desc`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': "Bearer " + localStorage.getItem("token"),
        },
      });
      const data = await response.json();
      console.log('Fetched delivery persons:', data);

      // Extract managers and sales executives from fetched data
      const fetchedExecutives = data.users || [];
      const fetchedManagers = Array.from(new Set(fetchedExecutives.map(exec => exec.assignedBy._id)))
        .map(managerId => fetchedExecutives.find(exec => exec.assignedBy._id === managerId).assignedBy);
      console.log('fetchedManagersfetchedManagers', fetchedExecutives)
      setManagers(fetchedManagers);
      setExecutives(fetchedExecutives);
      setSortedExecutives(fetchedExecutives);
      setTotalPages(data.totalPages || 1); // Update total pages from API
      setLoading(false);
    } catch (error) {
      console.error('Error fetching delivery persons:', error);
      setLoading(false);
    }
  };
  const handleSort = (option) => {
    let sortedList;
    switch (option) {
      case 'managerName':
        sortedList = [...sortedExecutives].sort((a, b) => {
          const managerA = managers.find((m) => m._id === a.assignedBy._id);
          const managerB = managers.find((m) => m._id === b.assignedBy._id);
          const nameA = managerA ? managerA.name : '';
          const nameB = managerB ? managerB.name : '';
          return nameA.localeCompare(nameB);
        });
        break;
      case 'recentlyAdded':
        sortedList = [...sortedExecutives].sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
        break;
      case 'oldestAdded':
        sortedList = [...sortedExecutives].sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt));
        break;
      default:
        sortedList = sortedExecutives;
    }
    setSortBy(option);
    setSortedExecutives(sortedList);
    setDropdownOpen(false); // Close the dropdown after selecting an option
  };


  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const handleManagerClick = async (id) => {
    console.log(id);
    try {
      const response = await fetch(`${BASEURL}delivery-person/${id}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': "Bearer " + localStorage.getItem("token")
        },
      });

      const data = await response.json();
      navigate(`/delivery-boy/${id}`, { state: { data } });
      // if(data.status == 200){
      // }
      // else console.log(data.message);
    } catch (error) {
      console.error(error);
    }
  };

  // Pagination controls
  const handlePageChange = (pageNumber) => {
    if (pageNumber > 0 && pageNumber <= totalPages) {
      setCurrentPage(pageNumber);
    }
  };

  return (
    <div className="p-4 bg-white p-6 rounded-lg shadow-lg">
      {loading && <Loader />}
      <div className="flex justify-between items-center">
        <h1 className="text-gray-800 text-2xl font-bold mb-4">Delivery Boy List</h1>
        <div className="relative inline-block text-left">
          <button
            className="inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none"
            id="options-menu"
            aria-haspopup="true"
            aria-expanded={dropdownOpen ? 'true' : 'false'}
            onClick={toggleDropdown}
          >
            Sort By
            <svg
              className={`-mr-1 ml-2 h-5 w-5 ${dropdownOpen ? 'transform rotate-180' : ''}`}
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
              aria-hidden="true"
            >
              <path
                fillRule="evenodd"
                d="M5.293 9.293a1 1 0 011.414 0L10 12.586l3.293-3.293a1 1 0 011.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                clipRule="evenodd"
              />
            </svg>
          </button>
          {dropdownOpen && (
            <div
              className="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5"
              role="menu"
              aria-orientation="vertical"
              aria-labelledby="options-menu"
            >
              <div className="py-1" role="none">
                <button
                  className={`block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 w-full text-left ${sortBy === 'managerName' ? 'bg-gray-200' : ''
                    }`}
                  role="menuitem"
                  onClick={() => handleSort('managerName')}
                >
                  Manager Name
                </button>
                <button
                  className={`block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 w-full text-left ${sortBy === 'recentlyAdded' ? 'bg-gray-200' : ''
                    }`}
                  role="menuitem"
                  onClick={() => handleSort('recentlyAdded')}
                >
                  Recently Added
                </button>
                <button
                  className={`block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 w-full text-left ${sortBy === 'oldestAdded' ? 'bg-gray-200' : ''
                    }`}
                  role="menuitem"
                  onClick={() => handleSort('oldestAdded')}
                >
                  Oldest Added
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
      <table className="min-w-full border border-gray-300 mt-4">
        <thead className="bg-gray-50">
          <tr>
            <th className="text-gray-800 border border-gray-300 px-1 py-1">Id</th>
            <th className="text-gray-800 border border-gray-300 px-2 py-2">Name</th>
            <th className="text-gray-800 border border-gray-300 px-2 py-2">Sales Executive Name</th>
            <th className="text-gray-800 border border-gray-300 px-2 py-2">Manager</th>
            <th className="text-gray-800 border border-gray-300 px-2 py-2">Region</th>
          </tr>
        </thead>
        <tbody className="bg-white">
          {filteredManagers.map((executive, index) => {
            console.log('executiveexecutiveexecutiveexecutive', executive)
            const manager = managers.find((m) => m._id === executive.assignedBy._id);
            console.log('executive?.salesExecutives[0]', executive)
            return (
              <tr key={executive._id}>
                <td className="text-gray-800 border border-gray-300 px-4 py-2">{executive._id}</td>
                <td className={`text-gray-800 border border-gray-300 px-4 py-2 cursor-pointer ${mode === 'Dark' ? 'text-white hover:text-blue-300' : 'text-black-500 hover:text-blue-700'} transition duration-300`} onClick={() => {

                  handleManagerClick(executive._id)
                }}>
                  {executive.name}
                </td>
                {console.log('executiveexecutive',executive)}
                <td className="text-gray-800 border border-gray-300 px-4 py-2">{executive?.salesExecutives[0]?.name || 'N/A'}</td>
                <td className="text-gray-800 border border-gray-300 px-4 py-2">{manager ? manager.name : 'Unknown'}</td>
                <td className="text-gray-800 border border-gray-300 px-4 py-2">{executive.region}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
      <div className="flex justify-between items-center mt-4">
        <button
          className="bg-gray-300 text-gray-800 py-2 px-4 rounded disabled:opacity-50"
          onClick={() => handlePageChange(currentPage - 1)}
          disabled={currentPage === 1}
        >
          Previous
        </button>
        <span className="text-black">
          Page {currentPage} of {totalPages == 0?1:totalPages}
        </span>
        <button
          className="bg-gray-300 text-gray-800 py-2 px-4 rounded disabled:opacity-50"
          onClick={() => handlePageChange(currentPage + 1)}
          disabled={currentPage === totalPages}
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default DeliveryBoyList;
