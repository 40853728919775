import React, { useContext, useEffect, useState } from 'react';
import Loader from './shared/Loader';
import { ThemeContext } from './ThemeContext';
import { useNavigate } from 'react-router-dom';
import { BASEURL } from './shared/Config';

const SalesExecutive = (searchQuery) => {
  const [sortedExecutives, setSortedExecutives] = useState([]);
  const [managers, setManagers] = useState([]);
  const [deliveryPeep, setDeliveryPeep] = useState([]);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [pageSize] = useState(10); // Number of items per page
  const [sortBy, setSortBy] = useState('default');
  const navigate = useNavigate();
  const { mode } = useContext(ThemeContext);
  // Fetch sales executives data with pagination
  const fetchExecutives = async (page = 1) => {
    try {
      setLoading(true);
      const response = await fetch(`${BASEURL}sales-executive?page=${page}&limit=${pageSize}&sortBy=createdAt:desc`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': "Bearer " + localStorage.getItem("token")
        },
      });
      const data = await response.json();

      // Process managers and delivery persons
      const managerIds = new Set();
      const deliveryIds = new Set();
      const newManagers = [];
      const newDeliveryPeep = [];

      data.users.forEach(executive => {
        const managedBy = executive.assignedBy;
        if (!managerIds.has(executive.assignedBy._id)) {
          managerIds.add(executive.assignedBy._id);
          newManagers.push(managedBy);
        }
        executive.deliveryPersons.forEach(dp => {
          if (!deliveryIds.has(dp._id)) {
            deliveryIds.add(dp._id);
            newDeliveryPeep.push(dp);
          }
        });
      });

      setManagers(newManagers);
      setDeliveryPeep(newDeliveryPeep);
      setSortedExecutives(data.users);
      setTotalPages(data.totalPages); // Assuming your API response includes totalPages
      setLoading(false);
    } catch (error) {
      console.error('Error fetching sales executives:', error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchExecutives(currentPage);
  }, [currentPage]);

  let filteredManagers = sortedExecutives;

  if (searchQuery.searchQuery.length > 2) {
    filteredManagers = sortedExecutives.filter(manager =>
      manager.name.toLowerCase().includes(searchQuery.searchQuery.toLowerCase())
    )

  }
  else filteredManagers = sortedExecutives;

  const handleSort = (option) => {
    let sortedList;
    switch (option) {
      case 'managerName':
        sortedList = [...sortedExecutives].sort((a, b) => {
          const managerA = managers.find((m) => m._id === a.assignedBy._id);
          const managerB = managers.find((m) => m._id === b.assignedBy._id);
          const nameA = managerA ? managerA.name : '';
          const nameB = managerB ? managerB.name : '';
          return nameA.localeCompare(nameB);
        });
        break;
      case 'recentlyAdded':
        sortedList = [...sortedExecutives].sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
        break;
      case 'oldestAdded':
        sortedList = [...sortedExecutives].sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt));
        break;
      default:
        sortedList = sortedExecutives;
    }
    setSortBy(option);
    setSortedExecutives(sortedList);
    setDropdownOpen(false); // Close the dropdown after selecting an option
  };

  const handleManagerClick = async (id) => {
    console.log(id);
    try {
      const response = await fetch(`${BASEURL}sales-executive/${id}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': "Bearer " + localStorage.getItem("token")
        },
      });

      const data = await response.json();
      navigate(`/sales-executive/${id}`, { state: { data } });
      // if(data.status == 200){
      // }
      // else console.log(data.message);
    } catch (error) {
      console.error(error);
    }
  };
  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  return (
    <div className="flex">
      {loading && <Loader />}
      <div className="flex-1 bg-white p-6 rounded-lg shadow-lg">
        <div className="flex items-center justify-between mb-4">
         <h1 style={{color:'black'}} className="text-2xl font-bold">Sales Executives List</h1>
          <div className="relative inline-block text-left">
            <div>
              <button
                className="inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none"
                id="options-menu"
                aria-haspopup="true"
                aria-expanded={dropdownOpen ? 'true' : 'false'}
                onClick={toggleDropdown}
              >
                Sort By
                <svg
                  className={`-mr-1 ml-2 h-5 w-5 ${dropdownOpen ? 'transform rotate-180' : ''}`}
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  aria-hidden="true"
                >
                  <path
                    fillRule="evenodd"
                    d="M5.293 9.293a1 1 0 011.414 0L10 12.586l3.293-3.293a1 1 0 011.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                    clipRule="evenodd"
                  />
                </svg>
              </button>
            </div>
            {dropdownOpen && (
              <div
                className="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5"
                role="menu"
                aria-orientation="vertical"
                aria-labelledby="options-menu"
              >
                <div className="py-1" role="none">
                  <button
                    className={`block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 w-full text-left ${sortBy === 'managerName' ? 'bg-gray-200' : ''}`}
                    role="menuitem"
                    onClick={() => handleSort('managerName')}
                  >
                    Manager Name
                  </button>
                  <button
                    className={`block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 w-full text-left ${sortBy === 'recentlyAdded' ? 'bg-gray-200' : ''}`}
                    role="menuitem"
                    onClick={() => handleSort('recentlyAdded')}
                  >
                    Recently Added
                  </button>
                  <button
                    className={`block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 w-full text-left ${sortBy === 'oldestAdded' ? 'bg-gray-200' : ''}`}
                    role="menuitem"
                    onClick={() => handleSort('oldestAdded')}
                  >
                    Oldest Added
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
        <table className="min-w-full border border-gray-300">
          <thead className="bg-gray-50">
            <tr>
              <th className="text-gray-800 border border-gray-300 px-1 py-1">Id</th>
              <th className="text-gray-800 border border-gray-300 px-2 py-2">Name</th>
              <th className="text-gray-800 border border-gray-300 px-2 py-2">Manager Name</th>
              <th className="text-gray-800 border border-gray-300 px-2 py-2">Region</th>
            </tr>
          </thead>
          <tbody className="bg-white">
            {filteredManagers.map((executive, index) => {
              const manager = managers.find((m) => m._id === executive.assignedBy._id);
              return (
                <tr key={executive._id}>
                  <td className="text-gray-800 border border-gray-300 px-4 py-2">{executive._id}</td>
                  {/* <td className="text-gray-800 border border-gray-300 px-4 py-2">{executive.name}</td> */}
                  <td className={`text-gray-800 border border-gray-300 px-4 py-2 cursor-pointer ${mode === 'Dark' ? 'text-white hover:text-blue-300' : 'text-black-500 hover:text-blue-700'} transition duration-300`} onClick={() => {

                    handleManagerClick(executive._id)
                  }}>
                    {executive.name}
                  </td>
                  <td className="text-gray-800 border border-gray-300 px-4 py-2">{manager?.name || 'N/A'}</td>
                  <td className="text-gray-800 border border-gray-300 px-4 py-2">{executive?.region}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
        <div className="flex justify-between items-center mt-4">
          <button
            className="bg-gray-300 text-gray-800 py-2 px-4 rounded disabled:opacity-50"
            onClick={() => handlePageChange(currentPage - 1)}
            disabled={currentPage === 1}
          >
            Previous
          </button>
          <span className="text-black">
            Page {currentPage} of {totalPages == 0?1:totalPages}
          </span>
          <button
            className="bg-gray-300 text-gray-800 py-2 px-4 rounded disabled:opacity-50"
            onClick={() => handlePageChange(currentPage + 1)}
            disabled={currentPage === totalPages}
          >
            Next
          </button>
        </div>
      </div>
    </div>
  );
};

export default SalesExecutive;
