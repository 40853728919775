import React, { useState, useContext, Fragment, useEffect } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faEdit, faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import { ThemeContext } from './ThemeContext'; // Make sure to import your ThemeContext
import Loader from './shared/Loader';
import { BASEURL } from './shared/Config';

const initialManagerState = {

  name: '',
  email: '',
  mobile: '',
  dob: '',
  address: '',
  region: '',
  password: '',
  confirmPassword: '',
};

export default function ManagerSection(searchQuery) {
  console.log('searchQuerysearchQ333uery', searchQuery)
  const [isOpen, setIsOpen] = useState(false);
  const [isPasswordModalOpen, setIsPasswordModalOpen] = useState(false);
  const [newManager, setNewManager] = useState(initialManagerState);
  const [editingManagerId, setEditingManagerId] = useState(null);
  const [managers, setManagers] = useState([]);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const [isDeleteConfirmationOpen, setIsDeleteConfirmationOpen] = useState(false);
  const [managerToDelete, setManagerToDelete] = useState(null);
  const [cities, setCities] = useState([])
  const navigate = useNavigate();
  const { mode } = useContext(ThemeContext);

  const openModal = (manager = initialManagerState) => {

    //console.log(manager);
    setIsOpen(true);
    setNewManager(manager);
    setEditingManagerId(manager._id || null);
  };
  let filteredManagers = managers;

  if (searchQuery.searchQuery?.length > 2) {
    filteredManagers = managers.filter(manager =>
      manager.name.toLowerCase().includes(searchQuery.searchQuery.toLowerCase())
    )

  }
  else filteredManagers = managers;

  const fetchManagers = async (page = 1) => {
    try {
      const response = await fetch(`${BASEURL}manager?page=${page}&limit=${10}&sortBy=createdAt:desc`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': "Bearer " + localStorage.getItem("token")
        },
      });
      const data = await response.json();
      console.log(data.users);
      setLoading(false);
      setManagers(data.users); // Extract the users array
      setCurrentPage(data.currentPage);
      setTotalPages(data.totalPages);
      setTotalItems(data.totalItems);
    } catch (error) {
      setLoading(false);
      console.error('Error fetching managers:', error);
    }
  };
  const fetchCity = async (page = 1) => {
    try {
      setLoading(true);
      const response = await fetch(`${BASEURL}cities`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        },
      });
      const data = await response.json();

      if (!data.error) {
        setCities(data?.map(i=>i.name));
      } else {
        console.log(data.error);
      }
    } catch (error) {
      console.error('Error fetching items:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    setLoading(true)
    fetchCity()
    fetchManagers();
  }, []);

  const closeModal = () => {
    setIsOpen(false);
    setNewManager(initialManagerState); // Reset form state when closing modal
    setEditingManagerId(null);
  };

  const openPasswordModal = () => {
    setIsPasswordModalOpen(true);
  };

  const closePasswordModal = () => {
    setIsPasswordModalOpen(false);
    setNewManager(initialManagerState); // Reset form state when closing modal
    setEditingManagerId(null);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    console.log('name', name)
    setNewManager({ ...newManager, [name]: value });
  };

  const handleNextStep = () => {
    const isFormComplete = newManager.name && newManager.email && newManager.mobile && newManager.dob && newManager.address && newManager.region;

    if (isFormComplete) {
      setIsOpen(false);
      openPasswordModal();
    } else {
      alert('Please fill out all fields.');
    }
  };
  const handleAddOrUpdateManager = async () => {
    const isFormComplete = newManager.name && newManager.email && newManager.mobile && newManager.dob && newManager.address && newManager.region && newManager.password && newManager.confirmPassword;

    if (isFormComplete && newManager.password === newManager.confirmPassword) {
      if (editingManagerId) {

        setManagers(prevManagers =>
          prevManagers?.map(manager =>
            manager._id === editingManagerId ?
              { ...newManager, _id: editingManagerId }
              // updatedtManagers
              : manager
          )
        );

      } else {
        // Ayush -> Post Manager Apic
        setLoading(true)
        fetch(`${BASEURL}manager`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': "Bearer " + localStorage.getItem("token")
          },

          body: JSON.stringify({
            mobile: newManager.mobile,
            password: newManager.password,
            name: newManager.name,
            email: newManager.email,
            dob: newManager.dob,
            address: newManager.address, // Assuming 'area' corresponds to 'address'
            region: newManager.region, // Assuming 'reason' corresponds to 'region'
          })
        }).then(res => res.json())
          .then(result => {



            if (!result.error) {
              alert(result.message)
              fetchManagers()

            }

          })
          .catch(e => {

            console.log('errorrrrrrrrrrrr', e)
          })
        /************************************************** 

       *************************************************/
      }
      closePasswordModal();
    } else {
      alert('Please fill out all fields and ensure passwords match.');
    }
  };


  const handleDeleteManager = async (id) => {
    setLoading(true)
    try {
      const response = await fetch(`${BASEURL}manager/${id}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': "Bearer " + localStorage.getItem("token")
        },
      });
      const data = await response.json();
      if (!data.error)
        console.log(data.message);
      else console.lod(data.error)
      setLoading(false)

    } catch (error) {
      setLoading(false)
      console.error(error);
    }
    setManagers(prevManagers => prevManagers.filter(manager => manager._id !== id));
    setIsDeleteConfirmationOpen(false);
  };

  const handleManagerClick = async (id) => {
    console.log(id);
    try {
      const response = await fetch(`${BASEURL}manager/${id}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': "Bearer " + localStorage.getItem("token")
        },
      });

      const data = await response.json();
      navigate(`/manager/${id}`, { state: { data } });
      // if(data.status == 200){
      setNewManager({
        _id: data._id,
        name: data.name,
        email: data.email,
        mobile: data.mobile,
        dob: data.dob,
        address: data.address, // Assuming 'area' corresponds to 'address'
        region: data.region, // Assuming 'reason' corresponds to 'region'
      });
      // }
      // else console.log(data.message);
    } catch (error) {
      console.error(error);
    }
  };

  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const toggleShowConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const handlePageChange = (newPage) => {
    if (newPage >= 1 && newPage <= totalPages) {
      setLoading(true)
      fetchManagers(newPage);
    }
  };

  const openDeleteConfirmation = (id) => {
    setManagerToDelete(id);
    setIsDeleteConfirmationOpen(true);
  };

  // Use this function when clicking the delete button
  const handleDeleteButtonClick = (id) => {
    openDeleteConfirmation(id);
  };


  return (
    <div className={`flex flex-col lg:flex-row gap-6 ${mode === 'Dark' ? 'bg-gray-800 text-white' : 'bg-gray-100'}`}>
      {
        loading &&

        <Loader />
      }
      <div className="w-full bg-white p-6 rounded-lg shadow-lg">
        <div className="flex justify-between items-center mb-4">
          <h1 className="text-2xl font-bold text-gray-800">Manager Section</h1>
          <button
            className={`bg-green-600 text-white py-2 px-4 rounded hover:text-white hover:bg-green-700 transition duration-100 ${mode === 'Dark' ? 'hover:bg-green-500' : 'hover:bg-green-700'}`}
            onClick={() => openModal()}
          >
            Add Manager
          </button>
        </div>
        <table className="min-w-full border border-gray-300">
          <thead className="bg-gray-50">
            <tr>
              <th className="text-gray-800 border border-gray-300 px-2 py-2 text-left">Manager Id</th>
              <th className="text-gray-800 border border-gray-300 px-2 py-2 text-left">Manager Name</th>
              <th className="text-gray-800 border border-gray-300 px-2 py-2 text-left">Email</th>
              <th className="text-gray-800 border border-gray-300 px-2 py-2 text-left">Phone</th>
              <th className="text-gray-800 border border-gray-300 px-2 py-2 text-left">Actions</th>
            </tr>
          </thead>
          <tbody className="bg-white">

            {

              filteredManagers?.map((manager) => (
                <tr key={manager._id}>
                  <td className="text-gray-800 border border-gray-300 px-4 py-2">{manager._id}</td>
                  <td className={`text-gray-800 border border-gray-300 px-4 py-2 cursor-pointer ${mode === 'Dark' ? 'text-white hover:text-blue-300' : 'text-black-500 hover:text-blue-700'} transition duration-300`} onClick={() => {

                    handleManagerClick(manager._id)
                  }}>
                    {manager.name}
                  </td>

                  <td className="text-gray-800 border border-gray-300 px-4 py-2">{manager.email}</td>
                  <td className="text-gray-800 border border-gray-300 px-4 py-2">{manager.mobile}</td>
                  <td className="text-gray-800 border border-gray-300 px-4 py-2 flex space-x-2">
                    <button
                      className={`text-blue-500 hover:text-blue-700 transition duration-300 ${mode === 'Dark' ? 'text-blue-300 hover:text-blue-500' : ''}`}
                      onClick={() => openModal(manager)}
                    >
                      <FontAwesomeIcon icon={faEdit} />
                    </button>
                    <button
                      className={`text-red-500 hover:text-red-700 transition duration-300 ${mode === 'Dark' ? 'text-red-300 hover:text-red-500' : ''}`}
                      onClick={() => handleDeleteButtonClick(manager._id)}
                    >
                      <FontAwesomeIcon icon={faTrash} />
                    </button>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
        <div className="flex justify-between items-center mt-4">
          <button
            className="bg-gray-300 text-gray-800 py-2 px-4 rounded disabled:opacity-50"
            onClick={() => handlePageChange(currentPage - 1)}
            disabled={currentPage === 1}
          >
            Previous
          </button>
          <span className="text-black">
            Page {currentPage} of {totalPages == 0?1:totalPages}
          </span>
          <button
            className="bg-gray-300 text-gray-800 py-2 px-4 rounded disabled:opacity-50"
            onClick={() => handlePageChange(currentPage + 1)}
            disabled={currentPage === totalPages}
          >
            Next
          </button>
        </div>
      </div>

      {/* Initial Form Modal */}
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex items-center justify-center min-h-full p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-4xl transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900 text-center">
                    {editingManagerId !== null ? 'Update Manager' : 'Add Manager'}
                  </Dialog.Title>
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mt-4">
                    <div className="flex flex-col space-y-2">
                      <div>
                        <label className="block mb-2 text-gray-700">Name</label>
                        <input
                          type="text"
                          name="name"
                          value={newManager.name}
                          onChange={handleInputChange}
                          className="w-full px-2 py-1 border rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
                        />
                      </div>
                      <div>
                        <label className="block mb-2 text-gray-700">Email</label>
                        <input
                          type="email"
                          name="email"
                          value={newManager.email}
                          onChange={handleInputChange}
                          className="w-full px-2 py-1 border rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
                        />
                      </div>
                      <div>
                        <label className="block mb-2 text-gray-700">Phone</label>
                        <input
                          type="text"
                          name="mobile"
                          maxLength={10}
                          value={newManager.mobile}
                          onChange={handleInputChange}
                          className="w-full px-2 py-1 border rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
                        />
                      </div>
                    </div>
                    <div className="flex flex-col space-y-2">
                      <div>
                        <label className="block mb-2 text-gray-700">Date of Birth</label>
                        <input
                          type="date"
                          name="dob"
                          value={newManager.dob}
                          onChange={handleInputChange}
                          className="w-full px-2 py-1 border rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
                        />
                      </div>
                      <div>
                        <label className="block mb-2 text-gray-700">Address</label>
                        <input
                          type="text"
                          name="address"
                          value={newManager.address}
                          onChange={handleInputChange}
                          className="w-full px-2 py-1 border rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
                        />
                      </div>
                      <div>
                        <label className="block mb-2 text-gray-700">Region</label>
                        <select
                          name="region"
                          value={newManager.region}
                          onChange={handleInputChange}
                          className="w-full px-2 py-1 border rounded"
                        >
                          <option value="" disabled>Select Region</option>
                          {cities?.map((city, index) => (
                            <option key={index} value={city}>{city}</option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>

                  <div className="mt-4 flex justify-end">
                    <button
                      type="button"
                      className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 mr-2"
                      onClick={handleNextStep}
                    >
                      Next
                    </button>
                    <button
                      type="button"
                      className="bg-gray-300 text-gray-700 px-4 py-2 rounded hover:bg-gray-400 focus:outline-none focus:ring-2 focus:ring-gray-300"
                      onClick={closeModal}
                    >
                      Cancel
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>

      {/* Password Modal */}
      <Transition appear show={isPasswordModalOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closePasswordModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex items-center justify-center min-h-full p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900 text-center">
                    {editingManagerId !== null ? 'Update Password' : 'Set Password'}
                  </Dialog.Title>
                  <div className="mt-4">
                    <div className="flex flex-col space-y-2">
                      <div className="relative">
                        <label className="block mb-2 text-gray-700">Password</label>
                        <input
                          type={showPassword ? 'text' : 'password'}
                          name="password"
                          value={newManager.password}
                          onChange={handleInputChange}
                          className="w-full px-2 py-1 border rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
                        />
                        <button
                          type="button"
                          className="absolute mt-3  inset-y-0 right-0 px-3 py-5 text-gray-700"
                          onClick={toggleShowPassword}
                        >
                          <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} />
                        </button>
                      </div>
                      <div className="relative">
                        <label className="block mb-2 text-gray-700">Confirm Password</label>
                        <input
                          type={showConfirmPassword ? 'text' : 'password'}
                          name="confirmPassword"
                          value={newManager.confirmPassword}
                          onChange={handleInputChange}
                          className="w-full px-2 py-1 border rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
                        />
                        <button
                          type="button"
                          className="absolute mt-3 inset-y-0 right-0 px-3 py-5 text-gray-700"
                          onClick={toggleShowConfirmPassword}
                        >
                          <FontAwesomeIcon icon={showConfirmPassword ? faEyeSlash : faEye} />
                        </button>
                      </div>
                    </div>
                  </div>

                  <div className="mt-4 flex justify-end">
                    <button
                      type="button"
                      className="bg-blue-600 text-white px-3 py-2 rounded hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-600 mr-2"
                      onClick={handleAddOrUpdateManager}
                    >
                      {editingManagerId !== null ? 'Update' : 'Add'}
                    </button>
                    <button
                      type="button"
                      className="bg-gray-300 text-gray-700 px-3 py-2 rounded hover:bg-gray-400 focus:outline-none focus:ring-2 focus:ring-gray-300"
                      onClick={closePasswordModal}
                    >
                      Cancel
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
      <Transition appear show={isDeleteConfirmationOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={() => setIsDeleteConfirmationOpen(false)}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex items-center justify-center min-h-full p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <Dialog.Title as="h2" className="text-lg mb-4 font-bold leading-6 text-gray-900 text-center">
                    Confirm Delete
                  </Dialog.Title>
                  <div className="mt-2">
                    <p style={{textAlign:'center',marginBottom:20}}>Are you sure you want to delete this manager?</p>
                  </div>
                  <div className="mt-4 flex justify-end">

                    <button
                      type="button"
                      className="bg-gray-300 text-gray-700 px-4 py-2 rounded hover:bg-gray-400 ml-2"
                      onClick={() => setIsDeleteConfirmationOpen(false)}
                    >
                      Cancel
                    </button>

                    <button
                      type="button"
                      className="bg-red-600 text-white px-4 py-2 ml-4 rounded hover:bg-red-700"
                      onClick={() => handleDeleteManager(managerToDelete)}
                    >
                      Delete
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </div>
  );
}
